.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content {
    text-align: center;
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.signout-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #ff0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.site-list {
    margin-top: 30px;
}