.globe {
    color: #949494;
    border-radius: 20px;
    border: 2px solid #949494;
}

.btn-text {
    background: transparent;
    border: none;
    color:#000;
}   