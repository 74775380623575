.auth-background {
  background: url('../../assets/img/background.jpg') center center fixed;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100dvh;
  min-width: 250px;
  position: relative;
}

.auth-card {
  background-color: white;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 496px;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 520px) {
  .auth-card {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    min-width: 230px;
  }
}

.input-group input {
  padding-right: 40px;
}

.input-group.error {
  border: 1px solid #DC3545;
  box-shadow: 0 0 0 0.25rem #DC354533;
  outline: none;
  border-radius: var(--bs-border-radius);
}

.footer-links {
  font-family: 'Figtree', 'Inter';
  font-size: '14px';
  font-weight: 500;
  line-height: '20px';
}
.footer-links a:hover{
  text-decoration: underline;
}

@media only screen and (max-width: 580px) {
  .container {
    padding-top: 60px;
  }
}

.auth-title-form {
  font-weight: 700;
  font-size: var(--fs-20);
  line-height: 20px;
  color: var(--black-color);
}

.auth-title-form svg {
  max-width: 80px;
  margin-left: 10px;
}



p.footer-links>a,
p.footer-links>span {
  text-decoration: none;
  color: #ADB5BD;
  padding-left: 20px;
}

.nav-link img {
  max-width: 84px;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
}

.optext {
  display: inline-block;
  vertical-align: top;
  color: #495057
}

.optext a {
  color: #212529;
  white-space: nowrap
}

.optextw {
  width: 92%;
}


.form-control:focus {
  border: 1px solid #54595E;
  box-shadow: 0 0 0 0.25rem rgba(16, 58, 140, 0.09);
  outline: none;
}


.label-text {
  margin-bottom: 4px;
}

.fw-bold {
  font-weight: 600;
}

.btn:active {
  background-color: #ADB5BD !important;
  border-color: #ADB5BD !important
}

.btn:disabled {
  background-color: #ADB5BD !important;
  border-color: #ADB5BD !important
}

a.btn {
  line-height: 35px;
}

.pb20 {
  padding-bottom: 20px;
}

.logoext {
  padding-bottom: 40px;
  padding-top: 120px;
}

.input-notification {
  color: #DC3545;
  font-size: 16px;
}