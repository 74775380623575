.skeleton-container {
  line-height: 1;
  position: relative;
}

.skeleton-pulse {
  display: inline-block;
  background-color: #d4d4d4;
  background-image: linear-gradient(
    100deg,
    #e8e8e8 20%,
    #fafafa 50%,
    #e8e8e8 60%
  );
  animation: placeholderShimmer 1.5s linear infinite forwards;
  background-size: 200% 100%;
  background-position: 0% 0%;
  animation-direction: normal;
}

.skeleton-pulse.no-animation {
  animation: none;
  background-image: none;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

.skeleton-circle {
  border-radius: 50%;
}

.skeleton-inline {
  display: inline-block;
}