@font-face {
    font-family: 'Figtree';
    src: url('../fonts/Figtree-VariableFont_wght.ttf') format('truetype');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root,
html,
body {
    height: 100%;
    width: 100%;
    background-color:rgba(20,23,26);
}

/* Colors */
:root {
    --primary-color: #212529;
    --black-color: #000000;
    --gray-color: #495057;
    --active-color: #222529;
    --fs-24: 24px;
    --fs-20: 20px;
    --fs-16: 16px;
    --fs-14: 14px;
    --fs-13: 13px;
    --bs-border-radius:6px;
}

/* Typography */
body {
    font-family: 'Figtree', 'Inter', sans-serif;
    font-weight: 400;
}

.auth-title-form {
    font-weight: 700;
    font-size: var(--fs-20);
    line-height: 20px;
    color: var(--black-color);
    padding-top:60px;
    padding-bottom:30px;
    margin:0px;
}

.body-text {
    font-size: var(--fs-16);
    line-height: 20px;
    text-decoration: none;
    color: var(--black-color);
}

.list-text {
    font-size: var(--fs-13);
    font-weight: 600;
    line-height: 15.73px;
    max-height:420px;
    overflow-y: auto;
    overflow-x: hidden;
}

.tiny-text {
    font-size: var(--fs-16);
    line-height: 20px;
    text-decoration: none;
    color: var(--gray-color);
}

input[type="password"].form-control {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    /* letter-spacing: 0.13em; */
    text-align: left;
}

a {
    color: rgba(var(--primary-color), var(--bs-link-opacity, 1));
}

a:hover {
    color: rgba(var(--primary-color), var(--bs-link-hover-opacity, 1));
}

/* sizes */
.h48 {
    height: 48px;
}

.mw-128 {
    min-width: 128px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}