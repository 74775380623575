.dropdown {
  background: white;
  border-radius: 16px;
  padding: 12px;
  margin-top: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 6;
}

.dropdownItem {
  color: #191B23;
  margin: 4px;
  height: 32px;
  border-radius: 8px;
  font-weight: 500;
  color: #6C6E79;
  font-size: 13px;
  padding-left: 10px;
}

.dropdownItem:hover {
  color: #000;
}

.dropdownItem.selected {
  background-color: #F4F5F9;
  color: #000;
  ;
}

.globeContainer {
  position: absolute;
  top: 60px;
  right: 60px;
  align-content: center;
  display: flex;
  flex-direction: column;
}

.globe svg {
  margin-right: 10px;
}

.globe svg path {
  stroke: #949494;
}

.globe:hover {
  color: #fff;
  stroke: #fff;
  border: 2px solid #fff;
}

.showDropdown .globe {
  color: #fff;
  stroke: #fff;
  border: 2px solid #fff;
}

.showDropdown .globe:active {
  background-color: transparent !important;
}

.showDropdown svg path {
  stroke: #fff;
}

@media only screen and (max-width: 580px) {
  .globeContainer {
    right: 20px;
  }

}